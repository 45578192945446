import React from "react";
import { Button, Drawer, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import useStyles from "./styles";

interface FailedProps {
  customer: string;
  message: string;
  modal: boolean;
  close: () => void;
}

const Failed: React.FC<FailedProps> = ({ customer, message, modal, close }) => {
  const classes = useStyles();

  return (
    <Drawer anchor="bottom" open={modal} onClose={close}>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          Falha!
        </Typography>

        <div className={classes.icon}>
          <WarningIcon fontSize="large" />
        </div>

        <Typography>
          <strong style={{ textTransform: "capitalize" }}>{customer}</strong>,
          seu cadastro foi recusado!
        </Typography>

        <Typography>{message}</Typography>

        <Button variant="contained" onClick={close}>
          OK entendi
        </Button>
      </div>
    </Drawer>
  );
};

export { Failed };
