import axios from "axios";
import { OnlyNumbersMask } from "react-masked-text/src/masks/only-numbers.mask";

const ceps = [
  {
    cep: "78890000",
    uf: "MT",
    coduf: "51",
    cidade: "Sorriso",
    codcidade: "5107925",
    bairro: "",
    rua: "",
  },
];

export const findCEP = async (numberCep) => {
  const onlyNumbers = new OnlyNumbersMask();
  const cep = onlyNumbers.getRawValue(numberCep);

  const foundLocal = ceps.find((item) => item.cep === cep);

  if (foundLocal) {
    return foundLocal;
  }

  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  const { data } = response;

  if (data.erro) {
    return {
      cep: "",
      uf: "",
      coduf: "",
      cidade: "",
      codcidade: "",
      bairro: "",
      rua: "",
    };
  }

  const { uf, localidade, bairro, logradouro, ibge } = data;

  return {
    cep,
    uf,
    coduf: ibge.substr(0, 2),
    cidade: localidade,
    codcidade: ibge,
    bairro,
    rua: logradouro,
  };
};
