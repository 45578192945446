import React from "react";
import { Button, Drawer, Typography } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import useStyles from "./styles";

interface SuccessProps {
  customer: string;
  modal: boolean;
  close: () => void;
}

const Success: React.FC<SuccessProps> = ({ customer, modal, close }) => {
  const classes = useStyles();

  return (
    <Drawer anchor="bottom" open={modal} onClose={close}>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          Sucesso!
        </Typography>

        <div className={classes.icon}>
          <DoneAllIcon fontSize="large" />
        </div>

        <Typography>
          <strong style={{ textTransform: "capitalize" }}>
            {customer.trim().split(" ")[0].toLowerCase()}
          </strong>
          , recebemos o seu cadastro!
        </Typography>

        <Typography>
          Aproveite os benefícios de ser um cliente cadastrado em nossa base de
          dados.
        </Typography>

        <Button variant="contained" onClick={close}>
          OK entendi
        </Button>
      </div>
    </Drawer>
  );
};

export { Success };
