import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { validate } from "gerador-validador-cpf";
import { findCEP } from "../../utils/findCEP";
import { toCPF, toCEP, toFONE } from "../../utils/masks";
import { onlyNumbers } from "../../utils/onlyNumbers";
import { Success } from "../../infra/components/Success";
import { Failed } from "../../infra/components/Failed";
import { Loading } from "../../infra/components/Loading";
import Splash from "../../assets/splash.png";
import api from "../../services/api";

const customerDefault = {
  nome: "",
  nascimento: "",
  sexo: "",
  cpf: "",
  cep: "",
  uf: "",
  coduf: "",
  cidade: "",
  codcidade: "",
  bairro: "",
  logradouro: "",
  numero: "",
  complemento: "",
  celular: "",
  email: "",
};

const sexos = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" },
];

interface Company {
  name: string;
}

interface Customer {
  nome: string;
  nascimento: string;
  sexo: string;
  cpf: string;
  cep: string;
  uf: string;
  coduf: string;
  cidade: string;
  codcidade: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
  celular: string;
  email: string;
}

interface SuccessProps {
  customer: string;
  modal: boolean;
}

interface FailedProps {
  customer: string;
  message: string;
  modal: boolean;
}

const Home: FC = () => {
  const classes = useStyles();

  const { token = "token" } = useParams();
  const { index, store } = useMemo(
    () => api(process.env.REACT_APP_TOKEN || token),
    [token]
  );

  const [starting, setStarting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company>({} as Company);
  const [customer, setCustomer] = useState<Customer>(customerDefault);
  const [nomeError, setNomeError] = useState<boolean>(false);
  const [nascimentoError, setNascimentoError] = useState<boolean>(false);
  const [sexoError, setSexoError] = useState<boolean>(false);
  const [cpfError, setCpfError] = useState<boolean>(false);
  const [cepError, setCepError] = useState<boolean>(false);
  const [ufError, setUfError] = useState<boolean>(false);
  const [cidadeError, setCidadeError] = useState<boolean>(false);
  const [bairroError, setBairroError] = useState<boolean>(false);
  const [logradouroError, setLogradouroError] = useState<boolean>(false);
  const [numeroError, setNumeroError] = useState<boolean>(false);
  const [celularError, setCelularError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const [success, setSuccess] = useState<SuccessProps>({
    customer: "",
    modal: false,
  });

  const [failed, setFailed] = useState<FailedProps>({
    customer: "",
    message: "",
    modal: false,
  });

  const handleChangeNome = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, nome: event.target.value }));
    setNomeError(false);
  };

  const handleChangeNascimento = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, nascimento: event.target.value }));
    setNascimentoError(false);
  };

  const handleChangeSexo = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, sexo: event.target.value }));
    setSexoError(false);
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, cpf: toCPF(event.target.value) }));
    setCpfError(false);
  };

  const handleChangeCep = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = toCEP(event.target.value);
    setCustomer((prev) => ({ ...prev, cep: value }));
    setCepError(false);

    if (value.length === 9) {
      const data = await findCEP(value);

      setCustomer((prev) => ({
        ...prev,
        uf: data.uf,
        coduf: data.coduf,
        cidade: data.cidade,
        codcidade: data.codcidade,
        bairro: data.bairro,
        logradouro: data.rua,
      }));

      setUfError(false);
      setCidadeError(false);
      setBairroError(false);
      setLogradouroError(false);
    }
  };

  const handleChangeLogradouro = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, logradouro: event.target.value }));
    setLogradouroError(false);
  };

  const handleChangeNumero = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, numero: event.target.value }));
    setNumeroError(false);
  };

  const handleChangeComplemento = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, complemento: event.target.value }));
  };

  const handleChangeBairro = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({ ...prev, bairro: event.target.value }));
    setBairroError(false);
  };

  const handleChangeCelular = (event: ChangeEvent<HTMLInputElement>) => {
    const value = toFONE(event.target.value);
    setCustomer((prev) => ({ ...prev, celular: value }));
    setCelularError(false);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((prev) => ({
      ...prev,
      email: event.target.value.trim().toLowerCase(),
    }));
    setEmailError(false);
  };

  const handleSubmit = useCallback(
    async (data: Customer) => {
      const nome = data.nome.trim().split(" ");
      const cnomeError = nome.length <= 1;
      setNomeError(cnomeError);

      const [year, month, day] = data.nascimento.trim().split("-");
      const nascimento = new Date(Number(year), Number(month) - 1, Number(day));
      const cnascimentoError = nascimento.toString() === "Invalid Date";
      setNascimentoError(cnascimentoError);

      const sexo = data.sexo.trim();
      const csexoError = sexo.length === 0;
      setSexoError(csexoError);

      const cpf = onlyNumbers(data.cpf);
      const ccpfError = cpf.length === 0 || !validate(cpf);
      setCpfError(ccpfError);

      const cep = onlyNumbers(data.cep);
      const ccepError = cep.length !== 8;
      setCepError(ccepError);

      const uf = data.uf.trim();
      const cufError = uf.length === 0;
      setUfError(cufError);

      const cidade = data.cidade.trim();
      const ccidadeError = cidade.length === 0;
      setCidadeError(ccidadeError);

      const bairro = data.bairro.trim();
      const cbairroError = bairro.length === 0;
      setBairroError(cbairroError);

      const logradouro = data.logradouro.trim();
      const clogradouroError = logradouro.length === 0;
      setLogradouroError(clogradouroError);

      const numero = data.numero.trim();
      const cnumeroError = numero.length === 0;
      setNumeroError(cnumeroError);

      const celular = onlyNumbers(data.celular);
      const ccelularError = celular.length !== 11;
      setCelularError(ccelularError);

      const email = data.email.trim();
      const cemailError = !/.+@.+\.[A-Za-z]+$/.test(email);
      setEmailError(cemailError);

      if (
        cnomeError ||
        cnascimentoError ||
        csexoError ||
        ccpfError ||
        ccepError ||
        cufError ||
        ccidadeError ||
        cbairroError ||
        clogradouroError ||
        cnumeroError ||
        ccelularError ||
        cemailError
      ) {
        return;
      }

      const validatedData = {
        nome: data.nome.trim(),
        nascimento: data.nascimento,
        sexo,
        cpf,
        cep,
        uf,
        coduf: data.coduf,
        cidade,
        codcidade: data.codcidade,
        bairro,
        logradouro,
        numero,
        complemento: data.complemento,
        celular,
        email,
      };

      setLoading(true);
      try {
        const { result } = await store(validatedData);
        const { success, data, error_message: message } = result;

        if (success && data === "created") {
          const name = nome[0].trim().toLowerCase();
          setSuccess((prev) => ({ ...prev, customer: name, modal: true }));
        } else {
          const name = nome[0].trim().toLowerCase();

          setFailed((prev) => ({
            ...prev,
            customer: name,
            message,
            modal: true,
          }));
        }
      } catch (error) {
        const name = nome[0].trim().toLowerCase();

        setFailed((prev) => ({
          ...prev,
          customer: name,
          message:
            "Falha ao tentar enviar os dados, favor verificar com um atendente.",
          modal: true,
        }));
      } finally {
        setLoading(false);
      }
    },
    [store]
  );

  const handleCloseModalSuccess = () => {
    setCustomer(customerDefault);
    setSuccess((prev) => ({ ...prev, modal: false }));
  };

  const beforeLoading = () => {
    return (
      <>
        <div className={classes.splash}>
          <img src={Splash} alt="" className={classes.avatar} />
        </div>
        <Loading loading={loading} />
      </>
    );
  };

  const afterLoading = () => {
    return (
      <>
        <div className={classes.root}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            <span>{company.name!}</span>
          </Typography>

          <TextField
            required
            id="nome"
            label="Nome completo"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.nome}
            onChange={handleChangeNome}
            size="small"
            error={nomeError}
          />

          <TextField
            required
            id="nascimento"
            label="Data de nascimento"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.nascimento}
            onChange={handleChangeNascimento}
            size="small"
            error={nascimentoError}
          />

          <TextField
            required
            id="sexo"
            select
            label="Sexo"
            type="text"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.sexo}
            onChange={handleChangeSexo}
            size="small"
            error={sexoError}
          >
            {sexos.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            id="cpf"
            label="CPF"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.cpf}
            onChange={handleChangeCpf}
            size="small"
            error={cpfError}
          />

          <TextField
            required
            id="cep"
            label="CEP"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.cep}
            onChange={handleChangeCep}
            size="small"
            error={cepError}
          />

          <TextField
            required
            disabled
            id="uf"
            label="UF"
            type="text"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.uf}
            size="small"
            error={ufError}
          />

          <TextField
            required
            disabled
            id="cidade"
            label="Cidade"
            type="text"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.cidade}
            size="small"
            error={cidadeError}
          />

          <TextField
            required
            id="bairro"
            label="Bairro"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.bairro}
            onChange={handleChangeBairro}
            size="small"
            error={bairroError}
          />

          <TextField
            required
            id="logradouro"
            label="Rua"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.logradouro}
            onChange={handleChangeLogradouro}
            size="small"
            error={logradouroError}
          />

          <TextField
            required
            id="numero"
            label="Número"
            type="text"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.numero}
            onChange={handleChangeNumero}
            size="small"
            error={numeroError}
          />

          <TextField
            id="complemento"
            label="Complemento"
            multiline
            minRows={2}
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.complemento}
            onChange={handleChangeComplemento}
            size="small"
          />

          <TextField
            required
            id="celular"
            label="Telefone"
            type="text"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.celular}
            onChange={handleChangeCelular}
            size="small"
            helperText="Por favor digite seu número de whatsapp"
            error={celularError}
          />

          <TextField
            required
            id="email"
            label="Conta de email"
            type="email"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.email}
            onChange={handleChangeEmail}
            size="small"
            helperText="Por favor digite seu melhor e-mail"
            error={emailError}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(customer)}
          >
            Enviar
          </Button>
        </div>

        <Success
          customer={success.customer}
          modal={success.modal}
          close={handleCloseModalSuccess}
        />

        <Failed
          customer={failed.customer}
          modal={failed.modal}
          message={failed.message}
          close={() => setFailed((prev) => ({ ...prev, modal: false }))}
        />

        <Loading loading={loading} />
      </>
    );
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const current = await index();
        setCompany(current.company);
        setStarting(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    load();
  }, [index]);

  return starting ? beforeLoading() : afterLoading();
};

export { Home };
