const api = (token) => {
  const baseURL = process.env.REACT_APP_API;

  const index = async function index() {
    const url = `${baseURL}/?t=${token}`;

    const res = await fetch(url, { method: "GET" });

    const data = await res.json();

    return data;
  };

  const store = async function store(fields) {
    const url = `${baseURL}/store/?t=${token}`;

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(fields),
    });

    const data = await res.json();

    return data || null;
  };

  return {
    index,
    store,
  };
};

export default api;
